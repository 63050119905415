import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { getSignInPagePath } from '../auth/components/sign-in/getSignInPagePath';
import { useGhostStore } from '../state/store';
import { getAccessToken } from './axiosAuth';

export const BASE_HEADERS = {
  'Ghost-App-Type': 'admin',
};

const baseConfig: AxiosRequestConfig = {
  headers: BASE_HEADERS,
  paramsSerializer: function (params: any) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
};

const makeAxiosApiClient = (baseURL: string, clerkEnabled: boolean) => {
  const axiosApiClient = axios.create({ ...baseConfig, baseURL, withCredentials: !clerkEnabled });

  if (clerkEnabled) {
    axiosApiClient.interceptors.request.use(async (config) => {
      config.headers.Authorization = `Bearer ${await getAccessToken()}`;
      return config;
    });
  }

  axiosApiClient.interceptors.response.use(undefined, async (error) => {
    if ((error as AxiosError).isAxiosError) {
      const axiosError = error as AxiosError<any>;
      const originalRequest = error.config;

      if (!axiosError.response) {
        throw new Error('No Internet or Server connection');
      }

      if (error.response.status === 401) {
        // this will be managed in `<AppWrapper />`
        if (originalRequest.url === '/users/accounts') return;

        if (originalRequest.url === '/auth/sign-in') return;

        useGhostStore.getState().setUser(null);
        // delete the cookie by setting the expiration date to a date in the past
        document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

        const attemptedPathname = window.location.pathname || '';
        const encodedRedirectPath =
          attemptedPathname !== '/' ? `?redirect=${encodeURIComponent(attemptedPathname)}` : '';
        window.location.href = getSignInPagePath(true) + encodedRedirectPath;
      }

      if (axiosError.response.data) {
        throw new Error(JSON.stringify(axiosError.response.data));
      }
    }
    throw new Error(error);
  });

  // put it in the global state for use by legacy code
  globalThis.axiosApiClient = axiosApiClient;
  globalThis.axiosInitialized = true;

  return axiosApiClient;
};

export default makeAxiosApiClient;
