'use client';

import { useEffect, useState } from 'react';
import { useFormState } from 'react-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { redirect, useSearchParams } from 'next/navigation';
import Link from '@/components/Link';
import SubmitButton from '@/components/SubmitButton';
import makeAxiosApiClient from '@/legacy/common/axiosApiClient';
import { handleSignIn } from './actions';

export default function Content({ baseURL }: { baseURL: string }): JSX.Element {
  const [state, formAction] = useFormState(handleSignIn, { valid: false });
  const [redirectPath, setRedirectPath] = useState<string>('/buyers');
  // this page is only available if clerk is disabled
  const axiosApiClient = makeAxiosApiClient(baseURL, false);

  const searchParams = useSearchParams();
  useEffect(() => {
    const path = searchParams.get('redirect');
    if (path) {
      setRedirectPath(path);
    }
  }, [searchParams]);

  useEffect(() => {
    if (state?.valid) {
      redirect(redirectPath);
    }
  }, [state?.valid, state?.user, redirectPath]);

  const emailError =
    state?.valid === false && state.errors?.email?.length ? state.errors.email[0] : undefined;

  const passwordError =
    state?.valid === false && state.errors?.password?.length ? state.errors.password[0] : undefined;

  const errorMessage = state?.valid === false && !!state.message ? state.message : undefined;

  const callFormAction = (payload: FormData) => {
    axiosApiClient
      .post('/auth/sign-in', {
        email: payload.get('email'),
        password: payload.get('password'),
      })
      .then(() => {
        formAction(payload);
      });
  };

  return (
    <Stack>
      <Stack direction="row">
        <form action={callFormAction}>
          <Stack spacing={2}>
            <Typography variant="h2">Welcome, Admin User</Typography>
            <TextField
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              error={!!emailError}
              helperText={emailError ? <Typography>{emailError}</Typography> : undefined}
            />
            <TextField
              label="Password"
              name="password"
              type="password"
              variant="outlined"
              error={!!passwordError}
              helperText={passwordError ? <Typography>{passwordError}</Typography> : undefined}
            />
            {!!errorMessage ? (
              <Typography color="typographyDanger">{errorMessage}</Typography>
            ) : undefined}
            <Stack direction="row" flexWrap="wrap">
              <SubmitButton variant="contained">Sign In</SubmitButton>
              <Link href="/auth/forgot" flex={1} textAlign="end">
                <Button variant="text">Forgot Password?</Button>
              </Link>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Stack>
  );
}
